exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-dark-jsx": () => import("./../../../src/pages/about/about-dark.jsx" /* webpackChunkName: "component---src-pages-about-about-dark-jsx" */),
  "component---src-pages-about-about-light-jsx": () => import("./../../../src/pages/about/about-light.jsx" /* webpackChunkName: "component---src-pages-about-about-light-jsx" */),
  "component---src-pages-blog-blog-dark-jsx": () => import("./../../../src/pages/blog/blog-dark.jsx" /* webpackChunkName: "component---src-pages-blog-blog-dark-jsx" */),
  "component---src-pages-blog-blog-light-jsx": () => import("./../../../src/pages/blog/blog-light.jsx" /* webpackChunkName: "component---src-pages-blog-blog-light-jsx" */),
  "component---src-pages-blog-details-blog-details-dark-jsx": () => import("./../../../src/pages/blog-details/blog-details-dark.jsx" /* webpackChunkName: "component---src-pages-blog-details-blog-details-dark-jsx" */),
  "component---src-pages-blog-details-blog-details-light-jsx": () => import("./../../../src/pages/blog-details/blog-details-light.jsx" /* webpackChunkName: "component---src-pages-blog-details-blog-details-light-jsx" */),
  "component---src-pages-blog-grid-blog-grid-dark-jsx": () => import("./../../../src/pages/blog-grid/blog-grid-dark.jsx" /* webpackChunkName: "component---src-pages-blog-grid-blog-grid-dark-jsx" */),
  "component---src-pages-blog-grid-blog-grid-light-jsx": () => import("./../../../src/pages/blog-grid/blog-grid-light.jsx" /* webpackChunkName: "component---src-pages-blog-grid-blog-grid-light-jsx" */),
  "component---src-pages-blog-list-blog-list-dark-jsx": () => import("./../../../src/pages/blog-list/blog-list-dark.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-dark-jsx" */),
  "component---src-pages-blog-list-blog-list-light-jsx": () => import("./../../../src/pages/blog-list/blog-list-light.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-light-jsx" */),
  "component---src-pages-blogs-b-2-b-partnership-alliances-jsx": () => import("./../../../src/pages/blogs/b2b-partnership-alliances.jsx" /* webpackChunkName: "component---src-pages-blogs-b-2-b-partnership-alliances-jsx" */),
  "component---src-pages-blogs-benefits-of-staff-augmentation-jsx": () => import("./../../../src/pages/blogs/benefits-of-staff-augmentation.jsx" /* webpackChunkName: "component---src-pages-blogs-benefits-of-staff-augmentation-jsx" */),
  "component---src-pages-blogs-corporate-training-programs-jsx": () => import("./../../../src/pages/blogs/corporate-training-programs.jsx" /* webpackChunkName: "component---src-pages-blogs-corporate-training-programs-jsx" */),
  "component---src-pages-blogs-digital-transformation-esdm-solutions-jsx": () => import("./../../../src/pages/blogs/digital-transformation-esdm-solutions.jsx" /* webpackChunkName: "component---src-pages-blogs-digital-transformation-esdm-solutions-jsx" */),
  "component---src-pages-blogs-hire-train-and-deploy-models-jsx": () => import("./../../../src/pages/blogs/hire-train-and-deploy-models.jsx" /* webpackChunkName: "component---src-pages-blogs-hire-train-and-deploy-models-jsx" */),
  "component---src-pages-blogs-maximizing-oracle-solutions-jsx": () => import("./../../../src/pages/blogs/maximizing-oracle-solutions.jsx" /* webpackChunkName: "component---src-pages-blogs-maximizing-oracle-solutions-jsx" */),
  "component---src-pages-blogs-navigating-the-future-of-logistics-jsx": () => import("./../../../src/pages/blogs/navigating-the-future-of-logistics.jsx" /* webpackChunkName: "component---src-pages-blogs-navigating-the-future-of-logistics-jsx" */),
  "component---src-pages-blogs-precision-testing-solutions-jsx": () => import("./../../../src/pages/blogs/precision-testing-solutions.jsx" /* webpackChunkName: "component---src-pages-blogs-precision-testing-solutions-jsx" */),
  "component---src-pages-blogs-revolutionizing-supply-chains-jsx": () => import("./../../../src/pages/blogs/revolutionizing-supply-chains.jsx" /* webpackChunkName: "component---src-pages-blogs-revolutionizing-supply-chains-jsx" */),
  "component---src-pages-blogs-shaping-industry-ready-graduates-jsx": () => import("./../../../src/pages/blogs/shaping-industry-ready-graduates.jsx" /* webpackChunkName: "component---src-pages-blogs-shaping-industry-ready-graduates-jsx" */),
  "component---src-pages-blogs-transform-ideas-digital-masterpiece-jsx": () => import("./../../../src/pages/blogs/transform-ideas-digital-masterpiece.jsx" /* webpackChunkName: "component---src-pages-blogs-transform-ideas-digital-masterpiece-jsx" */),
  "component---src-pages-contact-contact-dark-jsx": () => import("./../../../src/pages/contact/contact-dark.jsx" /* webpackChunkName: "component---src-pages-contact-contact-dark-jsx" */),
  "component---src-pages-contact-contact-light-jsx": () => import("./../../../src/pages/contact/contact-light.jsx" /* webpackChunkName: "component---src-pages-contact-contact-light-jsx" */),
  "component---src-pages-elf-about-us-jsx": () => import("./../../../src/pages/elf/about-us.jsx" /* webpackChunkName: "component---src-pages-elf-about-us-jsx" */),
  "component---src-pages-elf-buzz-and-chronicles-blogs-demo-jsx": () => import("./../../../src/pages/elf/buzz-and-chronicles/blogs-demo.jsx" /* webpackChunkName: "component---src-pages-elf-buzz-and-chronicles-blogs-demo-jsx" */),
  "component---src-pages-elf-buzz-and-chronicles-blogs-jsx": () => import("./../../../src/pages/elf/buzz-and-chronicles/blogs.jsx" /* webpackChunkName: "component---src-pages-elf-buzz-and-chronicles-blogs-jsx" */),
  "component---src-pages-elf-buzz-and-chronicles-insider-demo-jsx": () => import("./../../../src/pages/elf/buzz-and-chronicles/insider-demo.jsx" /* webpackChunkName: "component---src-pages-elf-buzz-and-chronicles-insider-demo-jsx" */),
  "component---src-pages-elf-buzz-and-chronicles-insider-jsx": () => import("./../../../src/pages/elf/buzz-and-chronicles/insider.jsx" /* webpackChunkName: "component---src-pages-elf-buzz-and-chronicles-insider-jsx" */),
  "component---src-pages-elf-buzz-and-chronicles-media-coverage-jsx": () => import("./../../../src/pages/elf/buzz-and-chronicles/media-coverage.jsx" /* webpackChunkName: "component---src-pages-elf-buzz-and-chronicles-media-coverage-jsx" */),
  "component---src-pages-elf-buzz-and-chronicles-sports-section-jsx": () => import("./../../../src/pages/elf/buzz-and-chronicles/sports-section.jsx" /* webpackChunkName: "component---src-pages-elf-buzz-and-chronicles-sports-section-jsx" */),
  "component---src-pages-elf-contact-digitalstudio-jsx": () => import("./../../../src/pages/elf/contact-digitalstudio.jsx" /* webpackChunkName: "component---src-pages-elf-contact-digitalstudio-jsx" */),
  "component---src-pages-elf-contact-us-jsx": () => import("./../../../src/pages/elf/contact-us.jsx" /* webpackChunkName: "component---src-pages-elf-contact-us-jsx" */),
  "component---src-pages-elf-cscs-jsx": () => import("./../../../src/pages/elf/cscs.jsx" /* webpackChunkName: "component---src-pages-elf-cscs-jsx" */),
  "component---src-pages-elf-engineering-jsx": () => import("./../../../src/pages/elf/engineering.jsx" /* webpackChunkName: "component---src-pages-elf-engineering-jsx" */),
  "component---src-pages-elf-it-consulting-services-jsx": () => import("./../../../src/pages/elf/it-consulting-services.jsx" /* webpackChunkName: "component---src-pages-elf-it-consulting-services-jsx" */),
  "component---src-pages-elf-observability-product-jsx": () => import("./../../../src/pages/elf/observability-product.jsx" /* webpackChunkName: "component---src-pages-elf-observability-product-jsx" */),
  "component---src-pages-elf-our-team-founder-jsx": () => import("./../../../src/pages/elf/our-team/founder.jsx" /* webpackChunkName: "component---src-pages-elf-our-team-founder-jsx" */),
  "component---src-pages-elf-our-team-team-jsx": () => import("./../../../src/pages/elf/our-team/team.jsx" /* webpackChunkName: "component---src-pages-elf-our-team-team-jsx" */),
  "component---src-pages-elf-product-jsx": () => import("./../../../src/pages/elf/product.jsx" /* webpackChunkName: "component---src-pages-elf-product-jsx" */),
  "component---src-pages-elf-staff-jsx": () => import("./../../../src/pages/elf/staff.jsx" /* webpackChunkName: "component---src-pages-elf-staff-jsx" */),
  "component---src-pages-homepage-home-5-dark-jsx": () => import("./../../../src/pages/homepage/home5-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-5-dark-jsx" */),
  "component---src-pages-homepage-home-5-light-jsx": () => import("./../../../src/pages/homepage/home5-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-5-light-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insider-client-visit-millenium-semi-jsx": () => import("./../../../src/pages/insider/client-visit-millenium-semi.jsx" /* webpackChunkName: "component---src-pages-insider-client-visit-millenium-semi-jsx" */),
  "component---src-pages-insider-elfonze-bts-2024-jsx": () => import("./../../../src/pages/insider/elfonze-bts-2024.jsx" /* webpackChunkName: "component---src-pages-insider-elfonze-bts-2024-jsx" */),
  "component---src-pages-insider-elfonze-corporate-training-jsx": () => import("./../../../src/pages/insider/elfonze-corporate-training.jsx" /* webpackChunkName: "component---src-pages-insider-elfonze-corporate-training-jsx" */),
  "component---src-pages-insider-elfonze-i-amps-merger-jsx": () => import("./../../../src/pages/insider/elfonze-iAMPS-merger.jsx" /* webpackChunkName: "component---src-pages-insider-elfonze-i-amps-merger-jsx" */),
  "component---src-pages-insider-elfonze-independance-day-jsx": () => import("./../../../src/pages/insider/elfonze-independance-day.jsx" /* webpackChunkName: "component---src-pages-insider-elfonze-independance-day-jsx" */),
  "component---src-pages-insider-elfonze-nasscom-pune-dc-jsx": () => import("./../../../src/pages/insider/elfonze-nasscom-pune-dc.jsx" /* webpackChunkName: "component---src-pages-insider-elfonze-nasscom-pune-dc-jsx" */),
  "component---src-pages-insider-elfonze-session-nemko-jsx": () => import("./../../../src/pages/insider/elfonze-session-nemko.jsx" /* webpackChunkName: "component---src-pages-insider-elfonze-session-nemko-jsx" */),
  "component---src-pages-insider-shabbir-oracle-ace-associate-jsx": () => import("./../../../src/pages/insider/shabbir-oracle-ace-associate.jsx" /* webpackChunkName: "component---src-pages-insider-shabbir-oracle-ace-associate-jsx" */),
  "component---src-pages-media-coverage-bhubaneswar-jsx": () => import("./../../../src/pages/media-coverage/bhubaneswar.jsx" /* webpackChunkName: "component---src-pages-media-coverage-bhubaneswar-jsx" */),
  "component---src-pages-media-coverage-blog-details-light-jsx": () => import("./../../../src/pages/media-coverage/blog-details-light.jsx" /* webpackChunkName: "component---src-pages-media-coverage-blog-details-light-jsx" */),
  "component---src-pages-media-coverage-embedded-training-jsx": () => import("./../../../src/pages/media-coverage/embedded-training.jsx" /* webpackChunkName: "component---src-pages-media-coverage-embedded-training-jsx" */),
  "component---src-pages-media-coverage-independence-day-2024-jsx": () => import("./../../../src/pages/media-coverage/independence-day-2024.jsx" /* webpackChunkName: "component---src-pages-media-coverage-independence-day-2024-jsx" */),
  "component---src-pages-media-coverage-nasscom-jsx": () => import("./../../../src/pages/media-coverage/nasscom.jsx" /* webpackChunkName: "component---src-pages-media-coverage-nasscom-jsx" */),
  "component---src-pages-media-coverage-new-ventures-vision-vigor-jsx": () => import("./../../../src/pages/media-coverage/new-ventures-vision-vigor.jsx" /* webpackChunkName: "component---src-pages-media-coverage-new-ventures-vision-vigor-jsx" */),
  "component---src-pages-media-coverage-pune-jsx": () => import("./../../../src/pages/media-coverage/pune.jsx" /* webpackChunkName: "component---src-pages-media-coverage-pune-jsx" */),
  "component---src-pages-mobile-app-mobile-app-dark-jsx": () => import("./../../../src/pages/mobile-app/mobile-app-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-mobile-app-dark-jsx" */),
  "component---src-pages-mobile-app-mobile-app-light-jsx": () => import("./../../../src/pages/mobile-app/mobile-app-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-mobile-app-light-jsx" */),
  "component---src-pages-mobile-app-portfolio-dark-jsx": () => import("./../../../src/pages/mobile-app/portfolio-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-portfolio-dark-jsx" */),
  "component---src-pages-mobile-app-portfolio-light-jsx": () => import("./../../../src/pages/mobile-app/portfolio-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-portfolio-light-jsx" */),
  "component---src-pages-mobile-app-pricing-plan-dark-jsx": () => import("./../../../src/pages/mobile-app/pricing-plan-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-pricing-plan-dark-jsx" */),
  "component---src-pages-mobile-app-pricing-plan-light-jsx": () => import("./../../../src/pages/mobile-app/pricing-plan-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-pricing-plan-light-jsx" */),
  "component---src-pages-mobile-app-services-dark-jsx": () => import("./../../../src/pages/mobile-app/services-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-services-dark-jsx" */),
  "component---src-pages-mobile-app-services-light-jsx": () => import("./../../../src/pages/mobile-app/services-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-services-light-jsx" */),
  "component---src-pages-mobile-app-shop-dark-jsx": () => import("./../../../src/pages/mobile-app/shop-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-shop-dark-jsx" */),
  "component---src-pages-mobile-app-shop-light-jsx": () => import("./../../../src/pages/mobile-app/shop-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-shop-light-jsx" */),
  "component---src-pages-project-details-2-project-details-2-dark-jsx": () => import("./../../../src/pages/project-details2/project-details2-dark.jsx" /* webpackChunkName: "component---src-pages-project-details-2-project-details-2-dark-jsx" */),
  "component---src-pages-project-details-2-project-details-2-light-jsx": () => import("./../../../src/pages/project-details2/project-details2-light.jsx" /* webpackChunkName: "component---src-pages-project-details-2-project-details-2-light-jsx" */),
  "component---src-pages-showcase-3-showcase-3-dark-jsx": () => import("./../../../src/pages/showcase3/showcase3-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-3-showcase-3-dark-jsx" */),
  "component---src-pages-showcase-3-showcase-3-light-jsx": () => import("./../../../src/pages/showcase3/showcase3-light.jsx" /* webpackChunkName: "component---src-pages-showcase-3-showcase-3-light-jsx" */),
  "component---src-pages-showcase-4-showcase-4-dark-jsx": () => import("./../../../src/pages/showcase4/showcase4-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-4-showcase-4-dark-jsx" */),
  "component---src-pages-showcase-4-showcase-4-light-jsx": () => import("./../../../src/pages/showcase4/showcase4-light.jsx" /* webpackChunkName: "component---src-pages-showcase-4-showcase-4-light-jsx" */),
  "component---src-pages-showcase-showcase-dark-jsx": () => import("./../../../src/pages/showcase/showcase-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-showcase-dark-jsx" */),
  "component---src-pages-showcase-showcase-light-jsx": () => import("./../../../src/pages/showcase/showcase-light.jsx" /* webpackChunkName: "component---src-pages-showcase-showcase-light-jsx" */),
  "component---src-pages-works-2-works-2-dark-jsx": () => import("./../../../src/pages/works2/works2-dark.jsx" /* webpackChunkName: "component---src-pages-works-2-works-2-dark-jsx" */),
  "component---src-pages-works-2-works-2-light-jsx": () => import("./../../../src/pages/works2/works2-light.jsx" /* webpackChunkName: "component---src-pages-works-2-works-2-light-jsx" */),
  "component---src-pages-works-3-works-3-dark-jsx": () => import("./../../../src/pages/works3/works3-dark.jsx" /* webpackChunkName: "component---src-pages-works-3-works-3-dark-jsx" */),
  "component---src-pages-works-3-works-3-light-jsx": () => import("./../../../src/pages/works3/works3-light.jsx" /* webpackChunkName: "component---src-pages-works-3-works-3-light-jsx" */),
  "component---src-pages-works-works-dark-jsx": () => import("./../../../src/pages/works/works-dark.jsx" /* webpackChunkName: "component---src-pages-works-works-dark-jsx" */),
  "component---src-pages-works-works-light-jsx": () => import("./../../../src/pages/works/works-light.jsx" /* webpackChunkName: "component---src-pages-works-works-light-jsx" */)
}

